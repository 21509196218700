.main--div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-working-div {
  background: #121212;
  width: 1000px;
  border: solid #ffa07a;
  border-radius: 25px;
  margin: auto;
  /* align-self: center; */
  /* align-items: center; */
}

.logoArea--Dix {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  display: flex;

  justify-content: flex-end;
  height: 100%;
}

.logo-img {
  align-self: center;
}

.formLogo {
  /* width: 120px; */
  /* margin-left: 30px; */
  /* margin-top: 30px; */
  -webkit-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  align-items: center;
}
.formName {
  margin-top: 30px;
  color: white;
  font-family: "Inter", sans-serif;
  margin-left: 30px;
}
.decurationHR {
  background-color: #ffa07a;
  width: 80px;
  height: 3px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
}

/* .row > div {
  border: solid 1px black;
} */

/* @media (200px <= width <= 400px){

    .Logo{
   
    }
    
    .Logo--img{
        width: 70%;
        padding-left: 50px;
        padding-bottom: 20px;
    
    }
    .main-working-div{
        max-width: 350px;
        border-radius: 25px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .formLogo{
        width: 100px;
        margin-left: 5px;
        margin-top: 30px;
        -webkit-user-drag: none;
        user-select: none;
        -webkit-user-select: none;
    }
    .formName{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 25px;
    }
    .decurationHR{
        background-color: #FFA07A;
        width: 100px;
        height: 3px;
        margin-left: 20px;
        margin-top: 40px;
    }

    .lable--div--noOFEmployee{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 25px;
    }

}


@media (400px <= width <= 500px){

    .Logo{
   
    }
    
    .Logo--img{
        width: 70%;
        padding-left: 50px;
        padding-bottom: 20px;
    
    }

    .main-working-div{
        max-width: 370px;
        border-radius: 25px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .formLogo{
        width: 100px;
        margin-left: 5px;
        margin-top: 30px;
        -webkit-user-drag: none;
        user-select: none;
        -webkit-user-select: none;
    }
    .formName{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 25px;
    }
    .decurationHR{
        background-color: #FFA07A;
        width: 100px;
        height: 3px;
        margin-left: 20px;
        margin-top: 40px;
    }

    .lable--div--noOFEmployee{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 25px;
    }

}



@media (500px <= width <= 800px){

    .Logo{
   
    }
    
    .Logo--img{
        width: 60%;
        padding-left: 100px;
        padding-bottom: 20px;
    
    }

    .main-working-div{
        max-width: 500px;
        border-radius: 25px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .formLogo{
        width: 100px;
        margin-left: 5px;
        margin-top: 30px;
        -webkit-user-drag: none;
        user-select: none;
        -webkit-user-select: none;
    }
    .formName{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 28px;
    }
    .decurationHR{
        background-color: #FFA07A;
        width: 100px;
        height: 3px;
        margin-left: 20px;
        margin-top: 40px;
    }

    .lable--div--noOFEmployee{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 25px;
    }

}


@media (800px <= width <= 850px){

    .Logo{
   
    }
    
    .Logo--img{
        width: 40%;
        padding-left: 300px;
        padding-bottom: 20px;
    
    }



    .main-working-div{
        max-width: 800px;
        border-radius: 25px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .formLogo{
        width: 100px;
        margin-left: 5px;
        margin-top: 30px;
        -webkit-user-drag: none;
        user-select: none;
        -webkit-user-select: none;
    }
    .formName{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 28px;
    }
    .decurationHR{
        background-color: #FFA07A;
        width: 100px;
        height: 3px;
        margin-left: 20px;
        margin-top: 40px;
    }

    .lable--div--noOFEmployee{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 25px;
    }

}




@media (850px <= width <= 1000px){
    .main-working-div{
        max-width: 850px;
        border-radius: 25px;
        margin-top: 100px;
        margin-bottom: 10px;
    }

    .formLogo{
        width: 100px;
        margin-left: 5px;
        margin-top: 30px;
        -webkit-user-drag: none;
        user-select: none;
        -webkit-user-select: none;
    }
    .formName{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 28px;
    }
    .decurationHR{
        background-color: #FFA07A;
        width: 100px;
        height: 3px;
        margin-left: 20px;
        margin-top: 40px;
    }

    .lable--div--noOFEmployee{
        color: white;
        font-family: 'Inter', sans-serif;
        margin-left: 20px;
        font-size: 25px;
    }

} */
