.row {
  margin-bottom: 10px;
}
span {
  font-size: 14px;
  color: #fff;
}

.lable-name {
  font-family: Inter;
  color: white;
  font-size: 16px;
  /* margin-left: 5px; */
}

.input {
  background-color: #121212;
  border: solid white;
  color: #ffa07a;
  font-family: Inter;
  font-size: 16px;
  /* width: 94%; */
  padding: 8px;
  /* margin-left: 20px; */
  border-radius: 5px;
}
.input:not(textarea) {
  line-height: 1;
  /* height: 1.7rem; */
  width: 100%;
  padding-left: 15px;
}

.h-center {
  padding: 20px;
  text-align: center;
}
.h-center .theme-button {
  color: var(--themeColor);
  border-color: var(--themeColor);
}
.h-center .theme-button:hover {
  background-color: var(--themeColor);
  color: #000;
}

.warning {
  display: inline;
  color: red;
  font-family: inter;
  font-size: 15px;
  text-align: center;
  padding-left: 10px;
}

.textarea-input {
  display: block;
  background-color: #121212;
  color: #ffa07a;
  font-family: Inter;
  font-size: 10px;
  margin-left: 1px;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
  height: 100px;
  border: solid white;
  resize: none;
}
